import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import filter from 'lodash/filter';
import head from 'lodash/head';
import { decrypt, encrypt, getKeyFromPem, hybridEncrypt } from 'helpers/crypto';
import { LocalError } from 'helpers/errorTypes';
import StorageExchangeTokenService from 'services/StorageExchangeTokenService';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import Account from 'modules/Account';
import Information from 'modules/Information';
import CloudDrive from 'modules/CloudDrive';
import Statistics from 'modules/Statistics';
import Patient from 'modules/Patient';
import * as actions from './actions';


function* createCgProfile() {
  try {
    let cgProfile = yield select(Account.selectors.cgProfile);
    if (cgProfile) {
      yield put(actions.createCgProfileSuccess(cgProfile));
      return;
    }
    const requestURL = '/api/Account/me/CgProfile';
    cgProfile = yield call(ApiService.regionalRequest, requestURL, {
      method: 'POST',
      body  : {},
    });
    yield put(actions.createCgProfileSuccess(cgProfile));
  } catch (err) {
    yield put(actions.createCgProfileError(err));
    yield call(App.dispatchError, err, Account.messages);
  }
}


function* createHcpProfile() {
  try {
    let hcpProfile = yield select(Account.selectors.hcpProfile);
    if (hcpProfile) {
      yield put(actions.createHcpProfileSuccess(hcpProfile));
      return;
    }
    const requestURL = '/api/Account/me/HcpProfile';
    hcpProfile = yield call(ApiService.regionalRequest, requestURL, {
      method: 'POST',
      body  : {},
    });
    yield put(actions.createHcpProfileSuccess(hcpProfile));
  } catch (err) {
    yield put(actions.createHcpProfileError(err));
    yield call(App.dispatchError, err, Account.messages);
  }
}


function* createPatientProfile({ payload }) {
  try {
    const {
      diabetesType,
      treatmentType,
      weight,
      height,
      payer,
      authorizationCode,
      standards,
      enrollCode,
      invitationCode,
    } = payload;

    let patientProfile = yield select(Account.selectors.patientProfile);
    if (patientProfile && patientProfile.encryptedExchangeToken) {
      yield put(actions.createPatientProfileSuccess(patientProfile));
    }

    const passphrase = yield select(Account.selectors.passphrase);
    const keyPair = yield select(Account.selectors.keyPair);
    const pubKeyObj = getKeyFromPem(keyPair.pubKeyPem);
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);

    if (!pubKeyObj) {
      const err = new LocalError({ code: 'NoPublicKey' });
      yield put(actions.createPatientProfileError(err));
      yield call(App.dispatchError, err, Account.messages);

    }

    if (!patientProfile) {
      const phiSetReferenceKey = uuid();
      const encryptedPhiSetReferenceKey = encrypt(phiSetReferenceKey, pubKeyObj);
      const requestURL = '/api/Account/me/PatientProfile';
      patientProfile = yield call(ApiService.regionalRequest, requestURL, {
        method: 'POST',
        body  : {
          encryptedPhiSetReferenceKey,
          payer,
        },
      });
      patientProfile.phiSetReferenceKey = phiSetReferenceKey;
    } else {
      patientProfile.phiSetReferenceKey = decrypt(patientProfile.encryptedPhiSetReferenceKey, prvKeyObj);
    }

    if (!patientProfile.countryId) {
      const countrySettings = yield select(Account.selectors.countrySettings);
      patientProfile.countryId = countrySettings.countryId;
    }

    const tokens = yield call(StorageExchangeTokenService.fetchExchangeToken,
      authorizationCode,
      'PatientProfile',
      patientProfile.patientProfileId,
    );
    const { exchangeToken, storageProvider, storageAccount } = tokens;
    const encryptedExchangeToken = yield call(hybridEncrypt, exchangeToken, pubKeyObj);

    const referenceKey = patientProfile.phiSetReferenceKey;
    const updatePatientProfileStorageTokenData = {
      storageProvider,
      exchangeToken,
      referenceKey,
      storageAccount,
      prvKeyObj,
    };

    yield put(Account.actions.updatePatientProfileStorageToken(updatePatientProfileStorageTokenData));
    const updatePatientProfileStorageTokenResult = yield take([
      Account.actionTypes.UPDATE_PATIENT_PROFILE_STORAGE_TOKEN_SUCCESS,
      Account.actionTypes.UPDATE_PATIENT_PROFILE_STORAGE_TOKEN_ERROR,
    ]);

    if (updatePatientProfileStorageTokenResult.type
      === Account.actionTypes.UPDATE_PATIENT_PROFILE_STORAGE_TOKEN_ERROR
    ) {
      yield put(actions.createPatientProfileError(updatePatientProfileStorageTokenResult.error));
      return;
    }

    const updateProfileData = {
      storageProvider,
      storageAccount,
      encryptedExchangeToken,
    };

    yield put(Account.actions.updatePatientProfile(updateProfileData));
    const updatePatientProfileResult = yield take([
      Account.actionTypes.UPDATE_PATIENT_PROFILE_SUCCESS,
      Account.actionTypes.UPDATE_PATIENT_PROFILE_ERROR,
    ]);

    if (updatePatientProfileResult.type === Account.actionTypes.UPDATE_PATIENT_PROFILE_ERROR) {
      yield put(actions.createPatientProfileError(updatePatientProfileResult.error));

    }

    patientProfile = { ...patientProfile, ...updateProfileData };
    patientProfile.accessToken = tokens;
    const sourceType = App.constants.PATIENT_DATABASES.GlucoControOnline.type;

    const encryptedStatisticalPersonalityId = encrypt(uuid(), pubKeyObj);

    const phiSetData = {
      diabetesType,
      treatmentType,
      encryptedStatisticalPersonalityId,
      summaryData: {
        lastWeight: weight,
        lastHeight: height,
      },
    };

    const information = yield select(Information.selectors.information);

    yield all([
      put(CloudDrive.actions.storeMeasurements(
        { weight, height },
        phiSetData,
        null,
        patientProfile,
      )),
      put(Statistics.actions.sendStatistics(
        { ...patientProfile, ...information, keyPair, sourceType },
        phiSetData,
        {},
        standards,
        passphrase,
      )),
    ]);

    const storeMeasurementsResult = yield take([
      CloudDrive.actionTypes.STORE_MEASUREMENTS_SUCCESS,
      CloudDrive.actionTypes.STORE_MEASUREMENTS_ERROR,
    ]);

    if (storeMeasurementsResult.type === CloudDrive.actionTypes.STORE_MEASUREMENTS_ERROR) {
      yield put(actions.createPatientProfileError(storeMeasurementsResult.error));
    }
    const { phiSet, phiSetDocumentId } = storeMeasurementsResult.payload;
    yield put(Patient.actions.setPhiSet(phiSet, phiSetDocumentId));

    const sharingRequests = yield call(ApiService.regionalRequest, '/api/SharingRequest/patient/me');
    const sharingRequestToBeApproved = head(filter(sharingRequests, { sharingStatus: 'Received' }));
    if (sharingRequestToBeApproved) {
      yield put(Patient.actions.approveSharingRequest(sharingRequestToBeApproved, patientProfile, phiSet));
      yield take([
        Patient.actionTypes.APPROVE_SHARING_REQUEST_SUCCESS,
        Patient.actionTypes.APPROVE_SHARING_REQUEST_ERROR,
      ]);
    }

    if (enrollCode) {
      yield put(Patient.actions.enrollInClinic(enrollCode, invitationCode));
      yield take([
        Patient.actionTypes.ENROLL_IN_CLINIC_SUCCESS,
        Patient.actionTypes.ENROLL_IN_CLINIC_ERROR,
      ]);
    }

    yield put(actions.createPatientProfileSuccess(patientProfile));
  } catch (err) {
    yield put(actions.createPatientProfileError(err));
    yield call(App.dispatchError, err, Account.messages);
  } finally {
    yield put(CloudDrive.actions.clearAuthorizationCode());
  }
}


function* sagas() {
  yield takeLatest(Account.actionTypes.CREATE_CG_PROFILE, createCgProfile);
  yield takeLatest(Account.actionTypes.CREATE_HCP_PROFILE, createHcpProfile);
  yield takeLatest(Account.actionTypes.CREATE_PATIENT_PROFILE, createPatientProfile);
}


export default [
  sagas,
];
